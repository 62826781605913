// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-en-js": () => import("./../../../src/pages/cv-en.js" /* webpackChunkName: "component---src-pages-cv-en-js" */),
  "component---src-pages-cv-ru-js": () => import("./../../../src/pages/cv-ru.js" /* webpackChunkName: "component---src-pages-cv-ru-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-student-card-js": () => import("./../../../src/pages/student-card.js" /* webpackChunkName: "component---src-pages-student-card-js" */)
}

