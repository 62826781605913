import React from 'react'
import {ThemeProvider, createGlobalStyle} from 'styled-components'
import {BaseStyles, theme as primer} from '@primer/components'

const GlobalStyle = createGlobalStyle`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

// a theme with custom spacing and font sizes
const theme = {
    ...primer,
    space: ['0', '0.3rem', '0.5rem', '1rem', '1.5rem', '2rem', '3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
    fontSizes: ['0.6rem', '0.9rem', '1rem', '1.3rem', '1.5rem', '2rem', '3rem', '4rem']
}

export function wrapRootElement({element}) {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

export function wrapPageElement({element}) {
  return (
    <>
      <GlobalStyle />
      <BaseStyles fontSize={2}>{element}</BaseStyles>
    </>
  )
}
